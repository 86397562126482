.react-parallax-bgimage {
  max-width: none;
}

.white-text span {
  color: white;
}

.general-paper {
  padding: 20px;
}

.sticky-categories {
  position: sticky;
  position: -webkit-sticky;
  background-color: #fff;
  z-index: 40;
  top: 80px;
}

.no-spin-button::-webkit-outer-spin-button,
.no-spin-button::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.ql-container {
  min-height: 79px;
}

.no-spin-button {
  -moz-appearance: textfield;
}

.rc-slider-tooltip {
  z-index: 9999 !important;
}

.search-controls {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.filter-expansion {
  flex-wrap: wrap;
  align-content: stretch;
}

.filter-expansion>div {
  width: 100%;
}

.DayPicker_portal__vertical {
  position: absolute !important;
  top: 80px;
  bottom: 0px;
  width: 100%;
}

.drp-error .DateRangePickerInput {
  border-color: red;
}

.mobileShowFlex {
  display: none;
}

.mobileShow {
  display: none;
}

@media only screen and (max-device-width: 650px) {
  .mobileShowFlex {
    display: flex;
  }

  .mobileShow {
    display: block;
  }
}

.test-me {
  padding-bottom: 7px;
  padding-top: 7px;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
  pointer-events: all;
  cursor: pointer;
}

.blur-on-hover:hover {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.centered-image-text {
  pointer-events: none;
  position: absolute;
  background-color: #000000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rangeslider__fill {
  background-color: #f05623 !important;
}

.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput {
  width: 100%;
}

.rbc-addons-dnd-resize-ns-anchor:last-child {
  height: 7px;
}

.rbc-addons-dnd-resize-ns-anchor:first-child {
  height: 7px;
}

.rbc-event-label {
  text-overflow: unset !important;
  white-space: normal !important;
}

.CustomCalendarDay:hover {
  background: #ffe8bc !important;
}

.removeImage {
  top: 10px;
  position: absolute;
  right: 10px;
  /* height: 20px; */
  width: 22px;
  border-radius: 10px;
  border-color: #fff;
  padding-left: 3.5px;
  background: #fff;
}

.slick-thumb {
  bottom: -85px;
}

.slick-thumb li {
  width: 60px;
  height: 45px;
}

.slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

/* TODO: TO REMOVE BORDER WHEN FINAL*/
.slick-thumb li.slick-active img {
  filter: grayscale(0%);
  /* border-color: blue;
  border-style: solid;
  border-width: 5px; */
}

.slick-slide .image {
  padding: 10px;
}

.slick-slide img {
  /* border: 5px solid #fff; */
  display: block;
  margin: auto;
  /* max-width: 80%; */
}

.slick-slide img.slick-loading {
  border: 0;
}

/* .slick-slider {
  margin: 30px auto 50px;
} */

/* .slick-next:before,
.slick-prev:before {
  color: #000;
} */

.slick-current {
  position: relative;
}

.error-text {
  color: #f44336;
}

@media only screen and (max-width: 767px) {
  .wizard h1 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wizard h1 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wizard h1 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 1199px) {
  .wizard h1 {
    font-size: 40px;
  }
}

.skiptiStripeElement {
  border-bottom: 0.5px solid lightgray;
  border-radius: 0px;
  padding: 12px;
  font-family: "Lato";
  margin-bottom: 24px;
}

.skiptiStripeElement:focus {
  border-bottom: 0.5px solid #f05623;
  border-radius: 0px;
  padding: 12px;
  margin-bottom: 24px;
}

.skiptiStripeLabelElement {
  font-size: 1rem;
}

.StripeElement--focus {
  border-bottom: 0.5px solid #f05623;
}

.weird-button-window {
  margin: 4px 7px !important;
  padding: 6px 12px !important;
  font-size: 13px !important;
  /* -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 5px !important; */
  /* border: 2px solid #f05623 !important; */
}

.weird-button-window {
  margin: 4px !important;
  padding: 6px 12px !important;
  border-radius: 100px !important;
  font-size: 12px !important;
  /* -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 5px !important; */
  /* border: 2px solid #f05623 !important; */
}

.bg-surfer {
  background-image: url("https://images.unsplash.com/photo-1506223064838-a9dff537fe98?ixlib=rb-1.2.1&auto=format&fit=crop&w=2425&q=80");
  background-position: top;
  background-size: 99%;
  background-repeat: no-repeat;
  position: relative;
}

.bg-surfer-layer {
  background-color: rgba(240, 86, 35, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hide-me {
  display: none;
}

.Select {
  height: 100% !important;
}

.rbc-month-view {
  height: 1000px !important;
}

.slick-slider {
  touch-action: auto;
  -ms-touch-action: auto;
}

body {
  color: #212529;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

.tw-max-h-inherit {
  max-height: inherit;
}

.tw-min-w-62 {
  min-width: 62rem;
}

.DateInput_input {
  font-weight: 300;
}

.dialog-with-date-picker>div>.MuiDialog-paper {
  overflow: visible;
}

.dialog-with-date-picker>div>.MuiDialog-paper>.MuiDialogContent-root {
  overflow: visible;
}

.with-underlines::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
  background-color: currentColor;
}

.with-underlines {
  position: relative;
  line-height: 1.5 !important;
}

.MuiDataGrid-root *,
.MuiDataGrid-root *::before,
.MuiDataGrid-root *::after {
  box-sizing: inherit !important;
}

.no-border-imp {
  border: none !important;
}

.unassigned {
  background-color: #e0e0e0;
  color: #666;
}

.assigned {
  background-color: #fbe08b;
  color: #666;
}

.started {
  background-color: #d7d2e9;
  color: #666;
}

.arrived-For-pickUp {
  background-color: #cbe2f1;
  color: #666;
}

.start-dropOff {
  background-color: #b7b0d9;
  color: #666;
}

.arrived-For-DropOff {
  background-color: #a2cce6;
  color: #666;
}

.drop-off-complete {
  background-color: #afdfd0;
  color: #666;
}

.drop-off-5-min-warn {
  background-color: #fac893;
  color: #666;
}

.pick-up-failed {
  background-color: #efb4ba;
  color: #666;
}

.autoassigned {
  background-color: #fac893 !important;
  color: #666;
}

.drop-off-failed {
  background-color: #efb4ba;
  color: #666;
}

.demo-session-started {
  background-color: #a2cce6;
  color: #666;
}

.demo-session-complete {
  background-color: #afdfd0;
  color: #666;
}

.arrived-for-return {
  background-color: #a2cce6;
  color: #666
}

.return-complete {
  background-color: #afdfd0;
  color: #666
}

.demo-start-failed {
  background-color: #efb4ba;
  color: #666
}

.return-failed {
  background-color: #efb4ba;
  color: #666
}

.customer-return-failed {
  background-color: #efb4ba;
  color: #666
}

.return-started {
  background-color: #b7b0d9;
  color: #666;
}

.chosenStatus {
  background-color: #000;
  color: #fff;
}

.drivers-notified {
  background-color: #ff9d99;
  color: #666;
}

.canceled {
  background-color: rgb(224, 32, 32);
  color: rgb(255, 255, 255);
}
.at-demo-location{
  background-color: rgb(163, 204, 229,1);
  color: #666;
}

.routesTabsWidth {
  width: 480px;
}

.changeStatusIcon {
  right: -2px;
  top: -5px;
}

.center-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-container {
  height: 50%;
  width: 50%;
}

.driver-shift>.MuiCheckbox-root {
  padding: 9px;
}

.paymentStyleCenter .MuiDialogTitle-root>h4 {
  text-align: center;
}

.paddingMarginRemover {
  padding: 0 !important;
  margin: 0 !important;
}

.skipti-dialog-btn {
  background-color: #f05623 !important;
  border: 0px !important;
  padding: 12px 16px !important;
  color: white !important;
}

.skipti-dialog-btn:hover {
  background-color: #ec4710 !important;
}

.skipti-dialog-secondary-btn {
  background-color: #e5e5e5 !important;
  border: 0px !important;
  padding: 12px 16px !important;
  color: #747474 !important;
}

.skipti-dialog-secondary-btn:hover {
  background-color: #d5d5d5 !important;
}

.skipti-dialogDisable-btn {
  background-color: #c0bcbc !important;
}

.locationContainer {
  padding: 6px;
  white-space: pre-wrap;
}

@media only screen and (max-width: 1375px) {
  .fontSizeQuery {
    font-size: 12px;
  }
}