.date-picker-container {
	position: relative;
	.fa {
		position: absolute;
		top: 2px;
		left: 0;
	}
	input {
		padding-left: 30px;
		position: absolute;
	    top: 0;
	    background-color: transparent !important;
	}
}
.datepicker{
	&.dropdown-menu {
	 	padding: 30px;
	
		.datepicker-days {
			width: 275px;
			thread {
				font-weight: normal !important;
			}
			table {
				width: 100%;
				thead {
					tr {
						th { font-weight: 500; }
						th.datepicker-switch { font-size: 18px; }
					}
				}
				tbody {
					&:before {
				    content: "-";
				    display: block;
				    line-height: 1em;
				    color: transparent;
					}
					tr {
						td:first-child { color: $color1; }
						td:last-child { color: $color1; }
						td.active {
							background-color: $color1;
						}
					}
				}
			}
		}
	}
 }

 