.calculator {
	margin: 150px 0;
	label {
		font-size: 15px;
	}
	input {
		width: 100%;
		padding: 8px 15px; 
		border-radius: 5px;
		border: 2px solid rgba(0,0,0,0.05);
	}
	.slider.slider-horizontal { 
		.slider-track {
			height: 5px;
		}
		.slider-selection,
		.slider-handle {
			background-image: none;
			background-color: $color2 !important;
		}
		.slider-selection {

		}
		.slider-handle {
			width: 15px;
		    height: 15px;
		}
	}
	
	input#slider {
		border: none;
		width: 125px;
		padding-top: 19px;
	}

}

