.search-controls {
  .btn-skipti-secondary:focus {
    border-color: $color1;
    background-color: $color1;
    color: white !important;
    span {
      color: white;
    }
  }
  .dropdown-menu {
    width: 400px;
    margin-top: 18px;
    border: 0;
    @extend .b-shadow;
    ul.search-list {
      li {
        margin-bottom: 8px;
        a {
          font-size: 19px;
        }
      }
    }
  }
  .search-ico,
  .search-ico-hover {
    position: relative;
    top: -2px;
    width: 20px;
    height: 20px;
  }

  // Swap Button Icons
  .search-ico-hover {
    display: none;
  }

  .search-ico,
  .search-ico-hover {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
  }

  .swap-skipti-icons:hover .search-ico,
  .swap-skipti-icons:focus .search-ico,
  .swap-skipti-icons:active .search-ico {
    display: none;
  }
  .swap-skipti-icons:hover .search-ico-hover,
  .swap-skipti-icons:focus .search-ico-hover,
  .swap-skipti-icons:active .search-ico-hover {
    display: inline-block;
  }

  .btn-group {
    border-radius: 5px;
    border: 2px solid $color6;

    .btn {
      background: white;
      padding: 8px 9px;
    }
    .btn.active {
      border-radius: 0;
      background-color: $color6;
      color: white;
    }
  }
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  box-shadow: none;
}
