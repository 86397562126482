.accessories {
    .box{
        border: solid 1px #ccc;
    }
    .title{
        font-family: Lato;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #000;
        
            }
    .thumbnail {
        max-width: 100px;
        max-height: 100px;
        margin: 0 10px 0 0;
    }
    .subtitle{
        font-family: Lato;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
    }
    .groupHeader{
        font-family: Lato;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.54);
    }
    .includes{
        font-family: Lato;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #212529;
        .includesTitle{
            font-weight: bold;
        }
    }
        }