.blog-index {
	.blog-img-lg { max-width: 80%; }
	.blog-img-sm { max-width: 80%; }

	
	.text-container {
		&.right {
			width: 100%;
			top: -20px;
			position: relative;
			right: 5%;
			padding-bottom: 48px !important;
		}

		&.bottom {
			width: 100%;
			top: -20px;
			position: relative;
			left: 5%;
			padding-bottom: 48px !important;
		}

	}

	@media (max-width: 575.98px) { 
		.featurette {
			margin: 50px 0;
		}
	}

}