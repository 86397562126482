.alert {
	border: 0;
	padding: 18px 25px;
	color: $color1;
	.close { 
		position: relative;
		top: -16px;
		font-size: 40px; padding: 22px;
	}
	&.alert-danger {
		background-color: lighten($color1, 38%);
	}
	.alert-link {
		color: $color1;
		border-bottom: 1px dotted $color1;
	}
}