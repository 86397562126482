// @font-face {
//   font-family: "Helvetica Neue Light";
//   src: local("☺"), url("../fonts/HelveticaNeue-Light.woff2") format("woff2"),
//     url("../fonts/HelveticaNeue-Light.woff") format("woff"),
//     url("../fonts/HelveticaNeue-Light.ttf") format("opentype"),
//     url("../fonts/HelveticaNeue-Light.eot"),
//     url("../fonts/HelveticaNeue-Light.svg#HelveticaNeueLight") format("svg");
// }

// @font-face {
//   font-family: "Helvetica Neue Regular";
//   src: local("☺"),
//     url("../fonts/helveticaneueltstd-roman-55.woff") format("woff"),
//     url("../fonts/helveticaneueltstd-roman-55.ttf") format("opentype"),
//     url("../fonts/helveticaneueltstd-roman-55.eot"),
//     url("../fonts/helveticaneueltstd-roman-55.svg#HelveticaNeueMedium")
//       format("svg");
// }

// @font-face {
//   font-family: "Helvetica Neue Medium";
//   src: local("☺"), url("../fonts/HelveticaNeue-Medium.woff2") format("woff2"),
//     url("../fonts/HelveticaNeue-Medium.woff") format("woff"),
//     url("../fonts/HelveticaNeue-Medium.ttf") format("opentype"),
//     url("../fonts/HelveticaNeue-Medium.eot"),
//     url("../fonts/HelveticaNeue-Medium.svg#HelveticaNeueMedium") format("svg");
// }

// @font-face {
//   font-family: "Helvetica Neue Bold";
//   src: local("☺"), url("../fonts/HelveticaNeueLTStd-Bd.woff2") format("woff2"),
//     url("../fonts/HelveticaNeueLTStd-Bd.woff") format("woff"),
//     url("../fonts/HelveticaNeueLTStd-Bd.ttf") format("opentype"),
//     url("../fonts/HelveticaNeueLTStd-Bd.eot"),
//     url("../fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueMedium") format("svg");
// }

/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/lato-v20-latin-100.eot'); /* IE9 Compat Modes */
  src: local("☺"),
       url('../fonts/lato-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v20-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v20-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v20-latin-100.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-100italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/lato-v20-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local("☺"),
       url('../fonts/lato-v20-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v20-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v20-latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v20-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v20-latin-100italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lato-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local("☺"),
       url('../fonts/lato-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v20-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/lato-v20-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local("☺"),
       url('../fonts/lato-v20-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v20-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v20-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v20-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v20-latin-300italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local("☺"),
       url('../fonts/lato-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v20-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/lato-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src: local("☺"),
       url('../fonts/lato-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v20-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lato-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local("☺"),
       url('../fonts/lato-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v20-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/lato-v20-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local("☺"),
       url('../fonts/lato-v20-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v20-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v20-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v20-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v20-latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lato-v20-latin-900.eot'); /* IE9 Compat Modes */
  src: local("☺"),
       url('../fonts/lato-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v20-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v20-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/lato-v20-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local("☺"),
       url('../fonts/lato-v20-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v20-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v20-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v20-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v20-latin-900italic.svg#Lato') format('svg'); /* Legacy iOS */
}

// Global Styles
* {
  font-family: "Lato";
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

// Global Links
a {
  &:link {
    text-decoration: none !important;
    color: $color1;
  }
  &:hover {
    color: lighten($color1, 10%);
  }
  &:visited {
    color: $color1;
  }
  &.active {
    color: $color1;
  }
}

// Granular Typography
p {
  font-size: 17px;
  letter-spacing: 0.02em;
  line-height: 1.6em;
}

label {
  font-size: 19px;
  @extend .fw-light;
  color: $color4;
  letter-spacing: 0.02em;
}

small {
  font-size: 14px;
}

.display-3 {
  font-size: 62px;
} // hero text

.display-4 {
  font-size: 25px;
} // hero text

h1 {
  font-size: 48px;
} // e.g.: Find your similar item
h2 {
  font-size: 38.65px;
} // item title details page
h3 {
  font-size: 24px;
} // wizard sidebar copy
h4 {
  font-size: 17px;
} // item title details page, footer section headers
h5 {
  font-size: 15px;
} // item title details page, footer section headers
h6 {
  font-size: 11px;
} // date in footer blog articles, legal links

// Weights
.fw-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
}
.fw-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
.fw-medium {
  font-family: "Lato", sans-serif;
  font-weight: 600;
}
.fw-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

// Leading
.lh-1 {
  line-height: 1;
}

p.dropcap:first-letter {
  font-family: "Lato", sans-serif;
  font-size: 101px;
  font-weight: 900;
  margin: 39px 0 -5px 0;
  padding: 0 0.1em 0 0;
  height: 0.7em;
  line-height: 0.5em;
  float: left;
  color: #333;
  *line-height: 1em; /* IE7 Only */
}

ul.skipti-list {
  padding-left: 40px;
  list-style: none;
  li {
    margin-bottom: 5px;
    &:before {
      font-family: "Font Awesome 5 Pro";
      content: "\f00c";
      margin: 0 15px 0 -30px;
      color: $color1;
    }
  }
}

blockquote {
  line-height: 1 !important;
}

.tw-font-lato{
  font-family: 'Lato';
}