table {
  thead {
    tr {
      th {
        font-weight: normal;
        border: 0 !important;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 0.35rem !important;
      }
    }
  }
  // tfoot {
  //   tr {
  //     td {
  //     }
  //   }
  // }
}

.data-grid-table {
  font-family: "Lato", sans-serif !important;
  & * {
    font-family: "Lato", sans-serif !important;
  }
}

@media (min-width: 768.1px) {
  .data-grid-table-border {
    border-color: #e2e8f0 !important;
    & * {
      border-color: #e2e8f0 !important;
    }
    & .MuiDataGrid-row {
      cursor: pointer;
    }
    .MuiDataGrid-columnsContainer {
      border-color: #000 !important;
      border-bottom-width: 2px !important;
    }
  }
}

.data-grid-table-bold-header {
  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
  }
}

.mui-d-g-divider-d-none .MuiDataGrid-columnSeparator {
  display: none !important;
}

.depots-status-cell {
  span {
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    // width: 7.75rem;
    width: 100%;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
    padding: 0.3125rem 0.875rem;
    border-radius: 0.25rem;
    &[data-status] {
      background-color: #f7b500;
    }
    &[data-status="CLOSED"] {
      background-color: #000;
    }
    &[data-status="URGENT"] {
      background-color: #000;
    }
    &[data-status="NEEDS SERVICE"] {
      background-color: #e02020;
    }
    &[data-status="UNKNOWN"] {
      background-color: #673ab7;
    }
    &[data-status="OK"] {
      background-color: #35b21b;
    }
  }
}

.loading_border {
  pointer-events: none;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9000;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cstyle%3E @keyframes reveal %7B to %7B stroke-dashoffset: 0; %7D %7D %3C/style%3E%3Cline stroke-width='6' x1='0' y1='0' x2='100%25' y2='0' stroke='orange' style=' stroke-dashoffset: 100%25; stroke-dasharray: 50%25; animation: reveal 2s linear 0s forwards infinite; '%3E%3C/line%3E%3Cline stroke-width='6' x1='0' y1='100%25' x2='100%25' y2='100%25' stroke='orange' style=' stroke-dashoffset: 100%25; stroke-dasharray: 50%25; animation: reveal 2s linear 0s reverse infinite; '%3E%3C/line%3E%3C/svg%3E"),
      rgba(0, 0, 0, 0.06);
  }
}

.chosen_border {
  pointer-events: none;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9000;
    border: 2px solid #f05623;
  }
}

.tw-items-stretch-i {
  align-items: stretch !important;
}
