@import url("https://fonts.googleapis.com/css?family=Crimson+Text");

.skipti-blog {
  position: relative;

  .featurette {
    margin: 40px 0 100px 0;
    .category {
      margin-bottom: 25px;
      letter-spacing: 0.08rem;
    }
    .title {
      font-size: 64px;
      line-height: 0.9;
      margin-bottom: 40px;
    }
    .byLine {
    }
    p {
      font-size: 17px;
    }
  }

  p {
    font-size: 22px;
    &.body {
      font-family: "Crimson Text", serif;
    }
  }
  blockquote {
  }

  .floating-related-item {
    max-width: 325px;
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}

@media (max-width: 575.98px) {
  .skipti-blog {
    .featurette {
      margin: 0px 0 -50px 0;
      img.flush-right {
        position: relative;
        right: -15px;
      }
      img.flush-left {
        position: relative;
        left: -15px;
      }
      .text-container.right {
        padding: 25px 15px 15px 0px !important;
        top: -50px;
        right: 0px;
        .category {
          font-size: 13px;
        }
        .title {
          font-size: 55px;
          margin-bottom: 30px;
        }
        .byLine {
          font-size: 13px;
        }
      }
      .text-container.left {
        top: -80px;
        left: 15px;
        margin-bottom: 120px;
        padding: 27px !important;
        p {
          font-size: 17px;
        }
      }
    }
    .social-media {
      li {
        margin-right: 20px;
      }
    }
    blockquote {
      width: 100% !important;
      font-size: 32px;
      margin: 60px 0 30px 0 !important;
    }
    p {
      font-size: 17px;
      &.dropcap:first-letter {
        font-size: 77px;
        margin: 31px 0 -7px -6px;
      }
    }
  }
}
