.slider.slider-horizontal { 
	width: 100% !important;
	.slider-track {
		background-image: none;
		box-shadow: none;
		background-color: $color7 !important;
	}
	.slider-selection,
	.slider-handle {
		background-image: none;
		background-color: $color1 !important;
	}
}

.slider-controls input#slider {
	border: none;
	width: 125px;
	padding-bottom: 12px;
}

