.item-details {
  margin-top: 55px;
  .item-img-container {
    position: relative;
    .gradient-fade {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30%;
      background-image: -webkit-linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
      background-image: -moz-linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
      background-image: -ms-linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
      background-image: -o-linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
    }
    .slideshow-controls {
      position: absolute;
      right: 25px;
      bottom: 25px;
      .btn-carousel {
        padding: 10px 8px 10px 10px;
        .svg-inline--fa.fa-w-14 {
          width: auto !important;
          border-left: 1px solid $color1;
        }
      }

      .photo-counter {
        margin-right: 10px;
        padding-right: 16px;
        padding-left: 5px;
      }

      .ico-play-slideshow {
        border-left: 1px solid $color1;
        width: 39px;
        height: 15px;
        position: relative;
        top: -1px;
        padding-left: 12px;
      }
      .ico-upload {
        width: 27px;
        height: 27px;
        position: relative;
        top: -3px;
      }
    }
  }

  .category {
    letter-spacing: 1px;
    margin-bottom: 17px;
  }

  .product-title {
    margin-bottom: 40px;
  }

  .item-description {
    font-size: 16px;
    line-height: 1.7;
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .meta-data {
    margin-bottom: 16px;
  }

  #sticky_item {
    margin-top: 6px;

    .rent-it-box {
      padding: 20px;
      .bundle-item {
        border-radius: 4px;
        padding: 9px 10px 13px 10px;
        margin-bottom: 15px;
        .price-box {
          padding-top: 6px;
          font-size: 13px;
        }
      }

      .rent-it-custom-bttn {
        padding: 8px;
        font-size: 21px;
        background: $color1;
        color: white;
        &:hover {
          border-color: darken($color1, 5%) !important;
          background: darken($color1, 5%) !important;
        }
      }

      .other-item-info {
        margin: 25px 0 4px 0;
      }
    }
  }
  .user-avatar {
    width: 40px;
    height: 40px;
    margin-right: 11px;
  }
  .owner-name {
    position: relative;
    top: 2px;
  }

  .btn-add-bundle {
    width: 52px;
    height: 42px;
  }

  .ico-boot {
    width: 30px;
    height: 30px;
  }

  .ico-stats {
    position: relative;
    top: -3px;
    width: 18px;
    height: 18px;
  }

  .mobile-fixed-pricebar {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

html.xs .item-details {
  padding-bottom: 120px;
}
html.xl .item-description {
  padding-right: 130px !important;
}
html.xs .item-description {
  padding-right: 0px;
}
html.sm .item-details {
  padding-bottom: 120px;
}

.content__mobileView {
  @media (max-width: 500px) {
    flex-direction: column;
  }
}
