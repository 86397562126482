@import "colors";
@import "typography";
@import "blog";
@import "blog-index";
@import "accordion";
@import "dashboard";
@import "alerts";
@import "buttons";
@import "calculator";
@import "calendar";
@import "category-list";
@import "datepicker";
@import "featurette";
@import "footer";
@import "forms";
@import "graphs";
@import "item-details";
@import "left-nav";
@import "hero";
@import "icons";
@import "login-register";
@import "modals";
@import "navbar";
@import "parallax";
@import "profile";
@import "progress-bars";
@import "reviews";
@import "search-controls";
@import "slider";
@import "static-pages";
@import "tables";
@import "tabs";
@import "tiles";
@import "utilities";
@import "wizard";
@import "testimonials";
@import "rent-details";
@import "termsAndConditions.scss";
@import "accessories";
@import "react_dates_overrides.scss";
@import "transportSelector.scss";

body {
  padding-top: 80px;
}

@media only screen and (max-width: 768px) {
  .tidio-chat-button-mobile {
    bottom: 4.5em !important;
  }
}

.tidio-chat-button-widget {
  display: "none";
}

@page {
  size: A4;
}

.print-page {
  table {
    width: 100%;
  }

  // table, td, th {
  //   border: 1px solid #cacaca;
  // }
  // th {
  //   border: 1px solid #9e9c9c !important;
  // }
}

@media print {
  html {
    font-size: 10px;
  }

  .print-page {
    padding: 0.75rem;
    max-width: 21cm;

    .print-page-main-info {
      flex-direction: row;
    }
  }

  .print-page>div.tw-flex {
    flex-direction: row;
  }
}

// .samo {
//   overflow-x: visible;
//   .MuiPaper-root {
//     background: transparent;
//     box-shadow: none;
//     overflow-x: visible;
//   }
//   .samo-2 {
//     position: relative;
//     display: flex;
//     overflow-x: visible;
//     button:first-child {
//       position: absolute;
//       top: 50%;
//       left: 0;
//       transform: translate(-200%, -50%);
//     }
//     button:last-child {
//       position: absolute;
//       top: 50%;
//       right: 0;
//       transform: translate(200%, -50%);
//     }
//   }
// }

.images-carousel {
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
    background: transparent;
    box-shadow: none;
    display: flex;
    justify-content: center;
    height: 100%;

    .slick-slider {
      margin: 0 2rem;
    }
  }
}
.contrast-disabled-input {
  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.87);
  }
}

.carousel-close-btn {
  position: fixed !important;
  top: 2rem;
  align-self: end;
  right: 2rem;
  color: #e0e0e0 !important;
}

.custom-columns {
  grid-template-columns: auto 1fr;
}