.panel-group .panel {
    border-radius: 0;
    box-shadow: none;
    border-color: #EEEEEE;
}

.panel-default > .panel-heading {
    padding: 0;
    border-radius: 0;
    color: #212121;
    border-color: #EEEEEE;
}

.panel-title {
    font-size: 17px;
    padding-right: 10px;
    a {
        border-bottom: 1px dotted $color6;
        color: $color3;
        display: block;
        padding: 15px 0;
        text-decoration: none;
        &:hover {
            color: $color5;
            
        }
        &[aria-expanded="true"]{ color: $color1;}
    }
}


.more-less {
    float: right;
    width: 20px;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #EEEEEE;
}