h3.tile-header {
	font-size: 26px;
	letter-spacing: 0.03em;
}

.item-tile {
	position: relative;

	.favorite-link {
		z-index: 10;
		position: absolute;
		right: 18px;
		top: 11px;
	}

	.item-link {
		z-index: 9;
	}

	p.category {
		margin-top: 16px;
		margin-bottom: 6px;
		font-size: 11px;
		letter-spacing: 0.07em;
		@extend .fw-regular;
	}

	p.item-title {
		@extend .fw-regular;
		font-size: 20px;
		line-height: 1.25;
	}

	.item-price {
		@extend .fw-regular;
		font-size: 20px;
		margin-bottom: 1px;
	}

	.item-stars {
		font-size: 12px;

		svg {
			display: inline-block;
			margin-right: 1px;
			opacity: 0.6;
		}

		.star-count {
			font-size: 12px;
			top: 1px;
			position: relative;
		}
	}

	.item-type-icons {
		margin-top: 10px;

		li {
			a {
				img.experience {
					width: 32px;
					height: 20px;
				}

				img.bundle {
					width: 20px;
					height: 20px;
				}

				img.featured {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	/* Fixes flickering */
	.slick-initialized .slick-slide {
		display: block;
		overflow: hidden;
	}

	// Item carousel
	.item-tile-carousel {

		height: 0;
		padding-bottom: 67.25%;
		position: relative;
		overflow: hidden;


		.slick-arrow {
			display: none !important;
		}

		&:hover {
			.slick-arrow {
				display: block !important;
			}
		}

		img {
			margin-bottom: 0;
			//   	position: relative;
			// top: 0; 
			// left: 0;
			border-radius: 5px;

			&:focus {
				outline: 0;
			}
		}

		.slick-prev,
		.slick-next {
			z-index: 10000;
		}

		.slick-prev {
			left: 15px;
		}

		.slick-next {
			right: 15px;
		}
	}

}

.show-all {
	font-size: 14px;
}

.item-tile-carousel .slick-prev:before {
	content: "\f104";
	font-family: 'Font Awesome 5 Pro';
	font-size: 22px;
}

.item-tile-carousel .slick-next:before {
	content: "\f105";
	font-family: 'Font Awesome 5 Pro';
	font-size: 22px;
}

.transport-unavailable-text {
	font-family: Lato;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #181818;

	// media query for desktop
	@media (min-width: 768px) {
		width: 99%;
	}
}