@import url("https://fonts.googleapis.com/css?family=Dancing+Script");
@import url("https://fonts.googleapis.com/css?family=Pacifico");

.skipti-hero {
  position: relative;
  width: 100%;
  //height: 100%;
  overflow: hidden;
  // margin-bottom: 50px;
  &.fit-screen-height {
    height: 100vh;
  }

  &.overlay:before {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.5)
    );
    opacity: 1;
    z-index: 3;
  }

  &.fade-bottom:after {
    pointer-events: none;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30%;
    z-index: 1;
    background-image: -webkit-linear-gradient(
      0deg,
      white,
      rgba(255, 255, 255, 0)
    );
    background-image: -moz-linear-gradient(0deg, white, rgba(255, 255, 255, 0));
    background-image: -ms-linear-gradient(0deg, white, rgba(255, 255, 255, 0));
    background-image: -o-linear-gradient(0deg, white, rgba(255, 255, 255, 0));
    background-image: linear-gradient(0deg, white, rgba(255, 255, 255, 0));
  }

  .content {
    position: relative;
    z-index: 2;
  }
  .search-container {
    width: 75%;
    position: absolute;
    z-index: 4;
    left: 0;
    right: 0;
    margin: 0 auto;
    .hero-headline {
      line-height: 1.1;
      font-family: inherit;
    }
  }

  .search-hero {
    position: relative;
    .fa-search {
      font-size: 19px;
      color: $color1;
      position: absolute;
      top: 43px;
      left: 22px;
    }
    input {
      font-size: 19px;
      padding: 20px;
      height: 74px;
      padding-left: 60px;
      border-radius: 5px;
      border: 0;
    }
  }
  .rotating-word {
    //font-family: "Dancing Script", cursive;
    font-family: "Pacifico", cursive;
    position: relative;
    //font-size: 80px;
  }
  .left-panel,
  .right-panel {
    height: 507px;
    .slick-prev,
    .slick-next {
      z-index: 10000;
      color: #ffffff !important;
    }
    .slick-prev {
      left: 15px;
    }
    .slick-next {
      right: 15px;
    }
    .slick-prev::before,
    .slick-next::before {
      color: #ffffff !important;
    }
    .slick-dots {
      bottom: 0;
      li button:before {
        color: white;
      }
    }
  }
  &.discover {
    height: 547px;
  }

  .owner-msg {
    font-size: 20px;
    .underline-link {
      border-bottom: 1px solid white;
      padding-bottom: 3px;
    }
  }
  @media (max-width: 5120.98px) {
    .search-container {
      .hero-headline {
        font-size: 43px;
      }
      .rotating-word {
        font-size: 125%;
      }
      width: 50%;
    }
  }
  @media (max-width: 1920.98px) {
    .search-container {
      .hero-headline {
        font-size: 43px;
      }
      .rotating-word {
        font-size: 125%;
      }
      width: 70%;
    }
  }
  @media (max-width: 1199.98px) {
    .search-container {
      .hero-headline {
        font-size: 45px;
      }
      .rotating-word {
        font-size: 125%;
      }
      width: 90%;
    }
  }
  @media (max-width: 991.98px) {
    .search-container {
      .hero-headline {
        font-size: 30px;
      }
      .rotating-word {
        font-size: 125%;
      }
      width: 80%;
    }
  }
  @media (max-width: 767.98px) {
    .search-container {
      .hero-headline {
        font-size: 25px;
      }
      .rotating-word {
        font-size: 125%;
      }
      width: 80%;
    }
  }
  @media (max-width: 575.98px) {
    .search-container {
      width: 90%;
      .hero-headline {
        font-size: 20px;
      }
      .rotating-word {
        font-size: 125%;
      }
      .owner-msg {
        font-size: 17px;
      }
      width: 85%;
    }
  }

  .hero-slideshow {
    position: relative;
    width: 100%;
    img {
      &.slide-img {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.ken-burns {
        animation: move 40s ease;
        -ms-animation: move 40s ease;
        -webkit-animation: move 40s ease;
        -moz-animation: move 40s ease;
      }
    }
  }

  @-webkit-keyframes move {
    0% {
      -webkit-transform-origin: center;
      -moz-transform-origin: center;
      -ms-transform-origin: center;
      -o-transform-origin: center;
      transform-origin: center;
      transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      -moz-transform: scale(1);
    }
    100% {
      transform: scale(1.2);
      -ms-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -o-transform: scale(1.2);
      -moz-transform: scale(1.2);
    }
  }
}
