.skipti-nav {
  height: 80px;
  .skipti-logo {
    img {
      height: 42px;
    }
  }
  .logo-dropdown {
    width: 12px;
    height: 7px;
  }
  .middle {
    font-size: 16px;
    // span {margin-right: 15px;}
    .navbar-search {
      position: relative;
      button {
        position: absolute;
        top: 1px;
        right: 2px;
        font-size: 16px;
        .fa-search {
        }
      }
      input {
        color: $black;
        padding: 17px 50px 17px 17px;
        height: 40px;
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        @extend .fw-regular;
        font-size: 15px;
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $color6;
          opacity: 0.5; /* Firefox */
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $color6;
        }
        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $color6;
        }
      }
    }
  }

  a.toplink {
    text-decoration: none;
    font-size: 15px;
    color: $color5;
    padding: 7px 8px;
    &:hover {
      color: $color3;
    }
  }
  // Hides dropdown caret from user icon
  .dropdown-toggle::after {
    display: none;
  }

  #navbar-dropdown {
    .fa-plus,
    .fa-minus {
      display: none;
    }
    ul.navbar-nav {
      li {
        &:not(:last-child) {
          border-bottom: 1px solid #d7d7d7;
        }
        a {
          font-size: 20px;
          &.nav-submenu:not(.collapsed) .fa-minus {
            display: inline-block;
          }
          &.nav-submenu.collapsed .fa-plus {
            display: inline-block;
          }
        }
        img.menu-arrow-right {
          width: 15px !important;
        }
      }
    }
  }
}

html.xl .skipti-nav #navbar-dropdown.collapse.show {
  display: none !important;
}

.veryLightBlack {
  background-color: rgb(240, 239, 239);
}

.marginOfProgressItems {
  margin-right: 80px;

  @media (max-width: 680px) {
    margin-right: 40px;
  }

  @media (max-width: 500px) {
    margin-right: 10px;
  }
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 125px;
  top: 15px;
  left: 100%;

  @media (max-width: 680px) {
    width: 85px;
  }
  @media (max-width: 500px) {
    width: 55px;
  }
}

.stepper-item.stepper-item__complete::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #f05623;
  width: 130px;
  top: 12px;
  left: 100%;

  @media (max-width: 680px) {
    width: 90px;
  }
  @media (max-width: 500px) {
    width: 60px;
  }
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
