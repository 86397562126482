path.domain {
	display: none;
}

.tick {
	line {display: none;}
	text { fill: #F05623;}
}

.c3-axis-x text {
  font-size: 15px; 
}