:root {
  --brandPrimary: #f05623;
  --brandPrimaryLight: #f87143;
}

.CalendarMonth_table_1 {
  background-color: #ffe8bc;
  td {
    background-color: white;
  }
}

.CalendarDay__selected {
  color: #ffffff;
  border: 0px;
  background-color: #ffe8bc !important;
}

.CalendarDay__default {
  border: 0;
  color: rgba(37, 50, 61, 0.3);
}

.CalendarDay__default:hover {
  background-color: white;
  color: rgba(37, 50, 61, 0.3);
  border: 0;
  cursor: default;
}

.DateRangePickerInput,
.SingleDatePickerInput {
  display: flex;
  align-items: center;
}

.DateRangePickerInput_arrow,
.SingleDatePickerInput_arrow {
  display: inline-block;
  color: #484848;
}

.date-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
}
.single-date-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
}

.DateInput {
  margin: 0;
  padding: 0;
  background-color: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle;
}

.DateRangePickerInput .DateInput:last-child,
.SingleDatePickerInput .DateInput:last-child {
  border-right: 0;
}

.DateRangePickerInput__disabled {
  background-color: #ffffff !important;
}

.DateInput_input {
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-bottom: 2px solid transparent;
  border-radius: 0;
}

.SingleDatePickerInput .DateInput {
  font-family: "Lato";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
  color: black !important;
}

.SingleDatePickerInput .DateInput_input::placeholder {
  font-family: "Lato";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}

.DateInput_input:hover {
  cursor: pointer;
}

label[for="startDate"],
label[for="endDate"] {
  font-family: "Lato";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 20px;
  margin-bottom: 0;
  width: 100%;
  padding-top: 16px;
  cursor: pointer;
  color: black;
}

label[for="startDate"] {
  border-right: 1px solid #d8d8d8;
}

label[for="singleDate"] {
  font-family: "Lato";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 20px;
  margin-bottom: 0;
  /* width: 100%; */
  padding-top: 16px;
  cursor: pointer;
  color: black;
}

.DateRangePicker_picker__fullScreenPortal,
.SingleDatePicker_picker__fullScreenPortal {
  background-color-color: rgba(0, 0, 0, 0.7);
}

.DateRangePicker_picker,
.SingleDatePicker_picker {
  display: flex;
}

.DayPicker_portal__horizontal {
  position: fixed;
  margin: 0 auto !important;
  top: unset;
  left: unset;
  bottom: 0;
  width: 100vw !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
}

.DayPicker_portal__horizontal > div {
  margin-top: 30px;
}

.CalendarDay__highlighted_calendar {
  background-color: #ffe8bc !important;
  border: 0px;
  color: #25323d;
  text-decoration: none;
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background-color: #ffe8bc !important;
  color: #25323d;
  cursor: pointer;
}

.CalendarDay__highlighted_calendar:hover .day-inner-wrapper {
  border-radius: 100%;
  background-color: transparent;
}

.CalendarDay__selected_span {
  background-color: var(--brandPrimaryLight) !important;
  color: white;
  border: 0px;
}

.CalendarDay__selected_span:hover {
  background-color: var(--brandPrimaryLight) !important;
  border: 0px;
}

.SingleDatePicker_picker .CalendarDay__selected {
  background-color: var(--brandPrimary) !important;
  border-radius: 100%;

  color: #ffffff;
  border: 0px;
}

.CalendarDay__selected:hover {
  border: 0px;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background-color: rgb(41, 27, 27);
}

.CalendarDay__blocked_out_of_range {
  color: rgba(37, 50, 61, 0.3);
  border: 0;
  text-decoration: line-through;
  background-color: #ffffff !important;
}

.CalendarDay__blocked_calendar {
  background-color: #cacccd !important;
}

.CalendarDay__defaultCursor.CalendarDay__blocked_out_of_range {
  cursor: default;
}

.CalendarDay__blocked_out_of_range:hover {
  background-color: #ffffff !important;
  color: rgba(37, 50, 61, 0.3);
  border: 0px;
}

.CalendarDay__blocked_out_of_range:hover .day-inner-wrapper {
  border: 0px;
}

.CalendarDay__blocked_out_of_range:active {
  background-color: #ffffff !important;
  color: rgba(37, 50, 61, 0.3);
  border: 0px;
}

.DateRangePicker_closeButton,
.SingleDatePicker_closeButton {
  color: black;
  padding: 12px;
  width: 48px;
  right: 16px;
  top: unset;
  bottom: 280px;
}

.DateRangePicker_closeButton_svg,
.SingleDatePicker_closeButton_svg {
  fill: black;
  height: 12px;
  width: 12px;
}

.DayPickerNavigation_button__default {
  border: 0px;
}

.DayPickerNavigation_svg__horizontal {
  fill: black;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover {
  background-color: var(--brandPrimaryLight) !important;
  color: #ffffff;
  border: 0px;
}

.CalendarDay__hovered_span:hover {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.CalendarDay__selected_start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  background-color: var(--brandPrimary) !important;
  cursor: not-allowed;
}

.CalendarDay__selected_start:hover,
.CalendarDay__selected_end:hover {
  background-color: var(--brandPrimary) !important;
  color: white;
}

.CalendarDay__selected_start:hover .day-inner-wrapper,
.CalendarDay__selected_end:hover .day-inner-wrapper {
  background-color: var(--brandPrimary) !important;
}

.CalendarDay__selected_end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: var(--brandPrimary) !important;
}

.day-outer-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.day-inner-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: relative;
  width: 100%;
  height: 100%;
}

.CalendarDay__selected_start .day-inner-wrapper,
.CalendarDay__selected_end .day-inner-wrapper {
  background-color: var(--brandPrimary) !important;
  color: white;
}

.error-text {
  padding-left: 16px;
  padding-right: 16px;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  border-right: 33px solid var(--brandPrimary) !important;
}
.DateInput_input__focused {
  border-bottom: 2px solid var(--brandPrimary) !important;
}
