.modal-inverse {
  background-color: $color1;
  color: white;
}

.modal-content {
  border: 0;
}

.modal-sm {
  max-width: 397px;
  .modal-body {
    padding: 60px !important;
  }
}

.modal-md {
  .modal-body {
    padding: 60px !important;
  }
}

// center modal
.modal .modal-dialog {
  transform: translate(0, -50%) !important;
  top: 50%;
  margin: 0 auto;
  background-color: white;
  color: $color3;
}

// prevents body from scrolling when modal is open
body.modal-open {
  overflow: hidden !important;
  padding-right: 15px !important;
}

.close-button-modal {
  padding: 1px 6px;
  border: none;
  background: none;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  @media (max-width: 355.98px) {
    top: 15px;
    right: 25px;
  }
}

.beta-popper {
  width: 450px;
  position: absolute;
  top: 5px;
  // right: 0;
  @media (max-width: 567.98px) {
    width: 320px;
  }
}

h4.mod-title-with-close-icon {
  padding-right: 36px;
  + .MuiIconButton-root {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.skipti-dialog {
  .MuiPaper-root::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    pointer-events: none;
    opacity: 0;
    transition: opacity 150ms linear;
    background: center / 40px no-repeat
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='svg-anim' viewBox='22 22 44 44'%3E%3Cstyle%3E .circle %7B stroke: %23f05623; %7D .circleIndeterminate %7B animation: keyframes-circular-dash 1.4s ease-in-out infinite; stroke-dasharray: 80px, 200px; stroke-dashoffset: 0px; %7D .svg-anim %7B animation: keyframes-circular-rotate 1.4s linear infinite; %7D @keyframes keyframes-circular-rotate %7B 0%25 %7B transform: rotate( 0 ); %7D 100%25 %7B transform: rotate( 360deg ); %7D %7D @keyframes keyframes-circular-dash %7B 0%25 %7B stroke-dasharray: 1px, 200px; stroke-dashoffset: 0px; %7D 50%25 %7B stroke-dasharray: 100px, 200px; stroke-dashoffset: -15px; %7D 100%25 %7B stroke-dasharray: 100px, 200px; stroke-dashoffset: -125px; %7D %7D %3C/style%3E%3Ccircle class='circle circleIndeterminate' cx='44' cy='44' r='20.2' fill='none' stroke-width='3.6' %3E%3C/circle%3E%3C/svg%3E"),
      rgba(0, 0, 0, 0.05);
  }
  &.skipti-dialog-loading {
    .MuiPaper-root::before {
      pointer-events: all;
      opacity: 1;
    }
  }
}

.products_list {
  //height: 340px;
  overflow-y: scroll;
  overflow-x: hidden;
}
