.listItem {
  color: white;
}

.locationBar {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

// .root {
//   background-color: black;
// }
