.feature.boxed {
  padding: 32px;
  background: #f8f8f8;
}

.cast-shadow-light {
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}
