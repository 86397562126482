.trasport-selector {
    .text1 {
        margin: 0px 0px 20px 0px;
        padding: 0px 10px;
        font-family: Lato;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #000;
    }

    .pick-an-option {
        margin: 0px 0px 16px 0px;
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
    }

    .button {
        height: 87px;
        margin-bottom: 10px;
        padding: 16px 10px 16px 10px;
        border-radius: 8px;
        border: solid 1px #ccc;
        background-color: #fff !important;
        text-transform: none;

        .title {
            font-family: Lato;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #000;
        }

        .oval {
            width: 14px;
            height: 14px;
            top: 10px;
            left: 10px;
            border: solid 1px #ccc;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
        }

        .oval-center {
            border-radius: 50%;
            background-color: #fff;
        }
    }

    .selected-button {
        height: 87px;
        margin-bottom: 10px;
        padding: 16px 10px 16px 10px;
        border-radius: 8px;
        border: solid 2px #f05623;
        background-color: #fff9f6 !important;
        text-transform: none;

        .title {
            font-family: Lato;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #f05623;
        }

        .oval {
            width: 14px;
            height: 14px;
            top: 10px;
            left: 10px;
            padding: 3px;
            border-radius: 50%;
            border: solid 1px #f05623;
            background-color: #fff;
            position: absolute;
        }

        .oval-center {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #f05623;
            margin: -1px -1px;
        }

    }

    .button-text {
        margin: 2px 0 0;
        padding: 0px 20px;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #666;

    }

    .continue-button-container {
        color: #fff !important;
    }

    .continue-button {
        width: 100%;
        height: 50px;
        margin-top: 30px;
        margin-bottom: 10px;
        padding: 1rem 1rem;
        border-radius: 4px;
        background-color: #333;
        color: #fff !important
    }

    .continue-button:hover {
        color: #f05623 !important;
        //background-color: #333;
        border: solid 2px #f05623;
        background-color: #fff9f6 !important;
        //width: 315px;
        height: 50px;
    }

    .service-area-text {
        margin-bottom: 2rem;
        font-family: Lato;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;

        .service-area-text .text-style-2 {
            color: #f05623;
        }
    }
}