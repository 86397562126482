.category-list {
  border-top: 2px solid $color7;
  .carousel-categories {
    pointer-events: auto;
    li {
      text-align: center;
      a {
        white-space: nowrap;
        color: $color6;
        @extend .fw-medium;
        font-size: 14px;
      }
    }

    .slick-prev {
      top: 10px;
      left: -16px;
      &:before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        content: "\f104";
        font-size: 25px;
        color: $color6;
      }
    }

    .slick-next {
      top: 12px;
      right: 0px;
      &:before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        content: "\f105";
        font-size: 25px;
        color: $color6;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .category-list {
    .fade-container {
      pointer-events: none;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 20%;
        height: 100%;
        z-index: 10;
        background-image: -webkit-linear-gradient(
          270deg,
          rgba(255, 255, 255, 0),
          white
        );
        background-image: -moz-linear-gradient(
          270deg,
          rgba(255, 255, 255, 0),
          white
        );
        background-image: -ms-linear-gradient(
          270deg,
          rgba(255, 255, 255, 0),
          white
        );
        background-image: -o-linear-gradient(
          270deg,
          rgba(255, 255, 255, 0),
          white
        );
        background-image: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0),
          white
        );
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 20%;
        height: 100%;
        z-index: 10;
        background-image: -webkit-linear-gradient(
          270deg,
          white,
          rgba(255, 255, 255, 0)
        );
        background-image: -moz-linear-gradient(
          270deg,
          white,
          rgba(255, 255, 255, 0)
        );
        background-image: -ms-linear-gradient(
          270deg,
          white,
          rgba(255, 255, 255, 0)
        );
        background-image: -o-linear-gradient(
          270deg,
          white,
          rgba(255, 255, 255, 0)
        );
        background-image: linear-gradient(
          270deg,
          white,
          rgba(255, 255, 255, 0)
        );
      }
    }
  }
}
