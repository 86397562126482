.rent-details {
    .box{
        border: solid 1px #ccc;
    }
    .title{
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #000;
            }
    .dates{
        opacity: 0.75;
        font-family: Lato;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #000;
            }
    .address {
        font-family: Lato;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000;
    }
    .thumbnail {
        max-width: 64px;
        max-height: 64px;
        margin: 0 10px 0 0;
    }
    .productName {
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
    }
    .productQuantity{
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #838383;
    }
    
      .productQuantity .text-style-1 {
        color: #000;
      }
      .productQuantity .text-style-2 {
        color: #f05623;
        cursor: pointer;
      }
      .productPrice { 
        font-family: Lato;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #000;
      }
      .productSubtotal {
       
        font-family: Lato;
        font-size: 16px;
        
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000;
      }
      .productSubtotal.bold {
        font-weight: bold;
      }
      .Taxes-fees-not-yet {
        width: 186px;
        height: 24px;
        margin: 10px 95px 0;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #000;
      }
      .creditCard{
        font-family: Lato;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
//   text-decoration: underline;
      }
}