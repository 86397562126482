@mixin generic-styles {
  font-family: "Lato";
  padding: 9px 23px;
  border-radius: 5px;
  text-decoration: none;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  text-align: center;
  font-size: 16px;
}

.btn-skipti-primary,
a.btn-skipti-primary:not([href]) {
  @include generic-styles;
  color: $color1;
  background-color: white;
  border: 2px solid $color1;
  &:hover {
    background-color: $color1;
    color: white;
  }
  &:active {
    background-color: darken($color1, 10%);
  }
  &.disabled {
    color: lighten($color1, 10%);
  }
}

.btn-skipti-secondary,
a.btn-skipti-secondary:not([href]) {
  @include generic-styles;
  color: $color6 !important;
  border: 2px solid $color6;
  background-color: white;
  &:hover {
    background-color: $color6;
    color: white !important;
  }
  &:active {
    background-color: darken($color6, 10%);
  }
  &.disabled {
    color: lighten($color6, 10%);
  }
}

.btn-skipti-inverse,
a.btn-skipti-inverse:not([href]) {
  @include generic-styles;
  color: $color1;
  border: 2px solid transparent;
  background-color: white;
  &:hover {
    background-color: white;
    border: 2px solid $color1;
    color: $color1;
  }
  &:active {
    border: 2px solid darken($color1, 10%);
    color: darken($color1, 10%);
  }
  &.disabled {
    background-color: lighten($color1, 10%);
    color: white;
    border: 2px solid lighten($color1, 10%);
  }
}

.btn-skipti-outline,
a.btn-skipti-outline:not([href]) {
  @include generic-styles;
  color: white !important;
  border: 2px solid white;
  background-color: $color1;
  &:hover {
    background-color: white;
    border: 2px solid $color1;
    color: $color1 !important;
  }
  &:active {
    border: 2px solid darken($color1, 10%);
    color: white;
  }
  &.disabled {
    background-color: lighten($color1, 10%);
    color: white;
    border: 2px solid lighten($color1, 10%);
  }
}

.btn-skipti-outline-transparent,
a.btn-skipti-outline-transparent:not([href]) {
  @include generic-styles;
  color: white !important;
  border: 2px solid white;

  &:hover {
    background-color: white;
    border: 2px solid $color1;
    color: $color1 !important;
  }
  &:active {
    border: 2px solid darken($color1, 10%);
    color: white;
  }
  &.disabled {
    background-color: lighten($color1, 10%);
    color: white;
    border: 2px solid lighten($color1, 10%);
  }
}

.btn-skipti-orange,
a.btn-skipti-orange:not([href]) {
  @include generic-styles;
  color: white !important;
  background-color: $color1;
  border: 2px solid $color1;
  &:hover {
    background-color: white;
    color: $color1 !important;
  }
  &:active {
    background-color: darken(white, 10%);
  }
  &.disabled {
    color: lighten(white, 10%);
  }
  &:visited {
    color: white !important;
    &:hover {
      color: $color1 !important;
    }
  }
}

// Swap Button Icons
.ico-hover {
  display: none;
}

.ico-default,
.ico-hover {
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;
}

.swap-skipti-icons:hover .ico-default,
.swap-skipti-icons:active .ico-default {
  display: none;
}
.swap-skipti-icons:hover .ico-hover,
.swap-skipti-icons:active .ico-hover {
  display: inline;
}
