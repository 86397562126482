.left-nav {
	li {
		margin-bottom: 15px;
		&.active {
			a { 
				@extend .fw-medium;
				color: $color1; 
				&:hover {
					color: $color1;
					cursor: default;
				}
			}
		}
		a {
			@extend .fw-medium;
			color: $color5;
			&:hover {
				color: $color6;
			}
		}
	}
}