.fc {
	// April - month/week/day - < >
	.fc-toolbar.fc-header-toolbar {
		padding: 15px 0px;
		margin-bottom: 0;
		.fc-left {
			h2 {
				font-size: 1.0em;  // month name
				margin-top: 5px;
			}
		}
		.fc-center {
			.fc-button-group {
				button.fc-state-default {
					font-size: 13px;
					background-image: none;
					box-shadow: none;
					font-weight: normal;
					background: white;
				}
				button.fc-state-active {
					background-color: $color3;
				    text-shadow: none;
					color: white;
				}
			}

		}
		.fc-right {
			.fc-button-group {
				button.fc-prev-button,
				button.fc-next-button {
					background: white;
					font-size: 13px;
					background-image: none;
					box-shadow: none;
					font-weight: normal;
					padding: 0 .2em;
				}
			}

		}
	}
	// Sun, Mon, Tue...
	.fc-head-container {
		border: 0;
		font-weight: 300;
		font-size: 13px;
		padding: 8px 0;
	} 
	.fc-view-container {
			.fc-head {
				.fc-day-header {
					padding-right: 10px;
				 	text-align: right;
				 	border: 0;
				}
			}
			.fc-body {
				.fc-widget-content {
					 .fc-row {
					 	min-height: 4em;
					 	a.fc-day-number {
					 		padding-right: 10px;
							font-size: 0.8em !important;
						    font-weight: normal;	
						}
					 }
					
				}
				
			}
		}
		.fc-event {
		    padding: 3px;
		    margin-top: 2px;
			font-size: 10px;
			font-weight: 300;
			border: 0;
			color: $color3;
		}
}

.calendar-legend {
	.legend-rent {background-color: #fdd9c9;}
	.legend-own {background-color: #d9ece8;}
}