*:focus {outline:none !important}

.input-icon-wrapper {
  	position: relative;
   	input {
       	position: relative;
        top: 0;
        background-color: transparent !important;
        padding-left: 30px;
    }
    svg {
        position: absolute;
        z-index: 1000;
        left: 0px;
        top: 9px;
    }
}

.form-control, 
.select2-selection.select2-selection--multiple, 
.select2-container--bootstrap.select2-container--focus 
.select2-selection, 
.select2-container--bootstrap.select2-container--open 
.select2-selection {
    border: 0;
    border-radius: 0 !important;
}
.form-control:focus, 
.select2-selection.select2-selection--multiple:focus, 
.select2-container--bootstrap.select2-container--focus .select2-selection:focus, 
.select2-container--bootstrap.select2-container--open .select2-selection:focus {
	outline: none;
	box-shadow: none !important;
	border-bottom: 1px dotted black;
}
.form-control, .select2-selection__rendered {
    border-bottom: 1px dotted #7F7F7F !important;
    padding: .375rem 0;
    font-size: 0.9em;
    font-weight: 400;
    border-radius: 0 !important;
}
.form-control:focus, .select2-selection__rendered:focus {
    border-bottom: 1px dotted #ef5f39 !important;
}
textarea.form-control {
    border: 1px dotted #7F7F7F !important;
    padding: .375rem .75rem;
	&:focus {
    	border: 1px dotted #ef5f39 !important;
	}
}
.parsley-errors-list.filled {
    list-style-type: none;
    padding-left: 0;
    color: #d9534f;
    margin-bottom: 0;
}
.custom-search-input {
    position: relative;
}
.custom-search-input .fa-search {
    position: absolute;
    top: 5px;
    right: 5px;
}
.btn-group > .btn-secondary {
    border: none;
    background: #7F7F7F;
}
.btn-group > .btn-secondary.active {
    background: $color5;
}
.pretty.p-icon .state .icon {
    left: 1px;
    top: -1px !important;
}
.pretty.p-icon .state .icon:before {
    padding-top: 8px;
    padding-left: 3px;
 }
.pretty.p-icon.p-round .state .icon:before {
    line-height: 1.1;
}

// Remove yellow chrome highlight for saved passwords 
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}
