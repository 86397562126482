.termsAndConditionScrollbar {

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 48px;
    border-radius: 10px;
    background-color: #b1b1b1;
    border: 3px solid#f2f2f2;
  }
}

.termsAndConditionsTextSize {
  h1,h2 {
    font-size: 24px !important;
  }

  h3 {
    font-size: 18px !important;
  }

  p {
    font-size: 14px !important;
  }
}

.weird-button-driver-availability {
  margin: 4px !important;
  padding: 6px 12px !important;
  border-radius: 100px !important;
  font-size: 12px !important;
  min-width: 36px !important;

  /* -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 5px !important; */
  /* border: 2px solid #f05623 !important; */
}
