.featurette {
	margin: 200px 0;
	position: relative;
	.text-container {
		width: 485px !important;
		&.right {
			top: 33%;
			position: absolute;
			right: 60%;
		}
		&.left {
			top: 33%;
			position: absolute;
			left: 60%;
		}
	}
}

.wow {
   visibility: hidden;
}

.parallax-window {
    min-height: 400px;
    background: transparent;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) { 
	.featurette {
		margin: 200px 0;
		position: relative;
		.text-container {
			width: 100% !important;
			&.right {
				width: 100%;
				top: -20px;
				position: relative;
				right: 5%;
				padding-bottom: 48px !important;
			}
			&.left {
				width: 100%;
				top: -20px;
				position: relative;
				left: 5%;
				padding-bottom: 48px !important;
			}
		}
	}
}

@media (max-width: 991.98px) { 
	.featurette {
		width: 100% !important; 
		margin: 100px 0;
		position: relative;
		.text-container {
			width: 100% !important;
			&.right {
				width: 100%;
				top: -20px;
				position: relative;
				right: 5%;
				padding-bottom: 58px !important;
			}
			&.left {
				width: 100%;
				top: -20px;
				position: relative;
				left: 5%;
				padding-bottom: 58px !important;
			}
		}
	}
}


