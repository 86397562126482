/* .slick-arrow {
  display: none !important;
}

.slick-arrow:hover {
  display: block !important;
} */
.slick-prev:before {
  content: "\f104";
  font-family: "Font Awesome 5 Pro";
  font-size: 32px;
  color: #e0e0e0;
}
.slick-next:before {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-size: 32px;
  color: #e0e0e0;
}
