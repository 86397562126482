$color1: #f05623; // orange
$color2: #6ac8ba; // blue
$color3: #484848; // darkgrey
$color4: #5b5b5b; // medium grey
$color5: #838383; // lightgrey
$color6: #acacac; // very light grey
$color7: #e0e0e0; // almost white
$black: #181818; // off black

.bg-black {
  background-color: $black;
}
.text-black {
  color: $black;
}

.bg-color1 {
  background-color: $color1 !important;
}
.text-color1 {
  color: $color1 !important;
}

.bg-color2 {
  background-color: $color2;
}
.text-color2 {
  color: $color2;
}

.bg-color3 {
  background-color: $color3;
}
.text-color3 {
  color: $color3;
}

.bg-color4 {
  background-color: $color4 !important;
}
.text-color4 {
  color: $color4 !important;
}

.bg-color5 {
  background-color: $color5;
}
.text-color5 {
  color: $color5;
}

.bg-color6 {
  background-color: $color6;
}
.text-color6 {
  color: $color6;
}

.bg-color7 {
  background-color: $color7;
}
.text-color7 {
  color: $color7;
}

.text-info {
  color: $color2;
}
