.nav-tabs {
	border-bottom: 1px solid $color1;
	a.nav-link {
		border-bottom: 4px solid transparent;
    	color: black;
    	.fa.d-block {
    		padding-top: 10px;
    	}
		&.active {
			color: $color1;
			border-color: transparent;
			border-bottom: 4px solid $color1 !important;
		}
		&:hover {
			border-color: transparent;
		}
	}
	
}