.skipti-footer {
  // margin-top: 150px;
  a:hover {
    text-decoration: none !important;
  }
  a.blog-link {
    color: $black;
    &:hover {
      img {
        opacity: 0.9;
      }
      color: $color4;
    }
  }
  ul.left-links,
  ul.legal-links {
    a {
      color: $color4;
      &:hover {
        color: $color6;
      }
    }
  }
  ul.legal-links a {
    font-size: 11px;
    padding: 0.5rem 0.5rem;
  }
  ul.social-media {
    margin-bottom: 20px;
    a {
      color: $color5;
      padding-left: 8px;
      &:hover {
        color: $color4;
      }
    }
  }
  img.skipti-logo-footer {
    height: 31px;
  }
  .copyright-text {
    font-size: 11px;
  }
  .newsletter {
    input.email-input-custom {
      border-bottom: 0;
      border: 2px solid $color7 !important;
      border-radius: 5px !important;
      padding: 5px 12px;
    }
    button {
      position: absolute;
      border: 0;
      background: none;
      float: right;
      right: 21px;
      top: -2px;
      @extend .fw-medium;
      &:focus {
        outline: none;
        color: darken($color1, 10%);
      }
    }
  }

  // Hides the last two blog items from footer in SM and MD breakpoints
  // Only show 3 items in large monitors
  @media (max-width: 991.98px) {
    #template-parent div[class^="col-"]:nth-child(3),
    #template-parent div[class^="col-"]:nth-child(4) {
      display: none;
    }

    .chatButtonForItemShow {
      bottom: 80px !important;
    }
    .chatButtonForArticleShow {
      bottom: 175px !important;
    }
  }
}
