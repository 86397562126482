.skipti-dashboard {
	.text-huge {font-size: 125px;
		margin: 0;
	    line-height: 1;
	}
	.text-medium {font-size: 55px;}
	.text-small {font-size: 25px;}

	hr {
		border-top: 1px solid lighten($color1, 30%);
	}
}

.Embed-container{
	height: 85vh;
	width: 100%;
}